import React, { Component } from 'react'
import {Segment, Container, Header, Grid, Icon} from 'semantic-ui-react';
import {t} from "../../i18n";

const FiberCollectTerms = "/FiberCollect-20200923.pdf";
const FiberSplitTerms = "/FiberSplit-20200518.pdf";
const FiberDirectTerms = "/FiberDirect-20210601.pdf";
const FiberForwardTerms = "/FiberForward-20210204.pdf";

export default class Services extends Component {

	render() {
		return (
			<Container>
				<Segment basic>
					<Header as='h2'>{t("services.descriptions")}</Header>

					<Grid stackable style={{ paddingTop: "30px", paddingBottom: "30px" }}>
						<Grid.Row columns={2} style={{ marginBottom: "30px" }}>
							<Grid.Column width={3} verticalAlign='top'>
								<Segment inverted textAlign='center' padded='very' id='services-segment'>
									<Icon name="sitemap" rotated='counterclockwise' size='huge' id="icon-white" />
									<Header as="h5">{t("services.fiberSplit.1")}</Header>
								</Segment>
							</Grid.Column>
							<Grid.Column width={13}>
								<Header color='blue'>{t("services.fiberSplit.2")}</Header>
								<p>{t("services.fiberSplit.content")}</p>
								<a href={FiberSplitTerms}>{t("services.readTerms")}</a>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row columns={2} style={{ marginBottom: "30px" }}>
							<Grid.Column width={3} verticalAlign='top'>
								<Segment inverted textAlign='center' padded='very' id='services-segment'>
									<Icon name="sitemap" rotated='clockwise' size='huge' id="icon-white" />
									<Header as="h5">{t("services.fiberCollect.1")}</Header>
								</Segment>
							</Grid.Column>
							<Grid.Column width={13}>
								<Header color='blue'>{t("services.fiberCollect.2")}</Header>
								<p>{t("services.fiberCollect.content")}</p>
								<a href={FiberCollectTerms}>{t("services.readTerms")}</a>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row columns={2} style={{ marginBottom: "30px" }}>
							<Grid.Column width={3} verticalAlign='top'>
								<Segment inverted textAlign='center' padded='very' id='services-segment'>
									<Icon name="arrow right" size='huge' id="icon-white" />
									<Header as="h4">{t("services.fiberDirect.1")}</Header>
								</Segment>
							</Grid.Column>
							<Grid.Column width={13}>
								<Header color="blue">{t("services.fiberDirect.2")}</Header>
								<p>{t("services.fiberDirect.content")}</p>
								<a href={FiberDirectTerms}>{t("services.readTerms")}</a>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row columns={2} style={{ marginBottom: "30px" }}>
							<Grid.Column width={3} verticalAlign='top'>
								<Segment inverted textAlign='center' padded='very' id='services-segment'>
									<Icon name="percent" size='huge' id="icon-white" />
									<Header as="h5">{t("services.fiberForward.1")}</Header>
								</Segment>
							</Grid.Column>
							<Grid.Column width={13}>
								<Header color="blue">{t("services.fiberForward.2")}</Header>
								<p>{t("services.fiberForward.content")}</p>
								<a href={FiberForwardTerms}>{t("services.readTerms")}</a>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row columns={2} style={{ marginBottom: "30px" }}>
							<Grid.Column width={3} verticalAlign='top'>
								<Segment inverted textAlign='center' padded='very' id='services-segment'>
									<Icon name="credit card" size='huge' id="icon-white" />
									<Header as="h5">{t("services.fiberAccept.1")}</Header>
								</Segment>
							</Grid.Column>
							<Grid.Column width={13}>
								<Header color="blue">{t("services.fiberAccept.2")}</Header>
								<p>{t("services.fiberAccept.content")}</p>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row columns={2} style={{ marginBottom: "30px" }}>
							<Grid.Column width={3} verticalAlign='top'>
								<Segment inverted textAlign='center' padded='very' id='services-segment'>
									<Icon name="id card" size='huge' id="icon-white" />
									<Header as="h5">{t("services.fiberAccount.1")}</Header>
								</Segment>
							</Grid.Column>
							<Grid.Column width={13}>
								<Header color="blue">{t("services.fiberAccount.2")}</Header>
								<p>{t("services.fiberAccount.content")}</p>
							</Grid.Column>
						</Grid.Row>

					</Grid>
				</Segment>
			</Container>
		)
	}
}
